/* Container für das Terminformular */
.appointment-form {
  max-width: 600px;
  margin: 50px auto;
  padding: 40px;
  background: linear-gradient(135deg, rgba(30, 30, 30, 0.95), rgba(50, 50, 50, 0.95));
  border-radius: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.8);
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  color: #ffffff;
  z-index: 1;
}

/* Dekorativer Akzent */
.appointment-form::before {
  content: '';
  position: absolute;
  top: -20%;
  left: -20%;
  width: 160%;
  height: 160%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.05), transparent);
  z-index: 0;
  pointer-events: none;
  animation: rotateGradient 10s linear infinite;
}

@keyframes rotateGradient {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Logout-Button oben rechts */
.logout-button {
  position: absolute;
  top: 15px;
  right: 20px;
  padding: 12px 20px;
  font-size: 14px;
  background: linear-gradient(135deg, #3e3e3e, #4f4f4f);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  z-index: 10;
}

.logout-button:hover {
  background: linear-gradient(135deg, #4f4f4f, #5f5f5f);
  transform: scale(1.05);
}

/* Titel: Termin buchen */
.appointment-form h2 {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #ffffff;
  position: relative;
}

.appointment-form h2::after {
  content: '';
  width: 70px;
  height: 4px;
  background: rgba(255, 255, 255, 0.4);
  display: block;
  margin: 10px auto 0;
  border-radius: 3px;
}

/* Formularelemente */
.appointment-form form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
  z-index: 2;
}

/* Eingabefelder und Dropdowns */
.appointment-form input,
.appointment-form select {
  padding: 18px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background: rgba(40, 40, 40, 0.9);
  color: #ffffff;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.appointment-form input::placeholder,
.appointment-form select::placeholder {
  color: rgba(200, 200, 200, 0.7);
  font-style: italic;
}

.appointment-form input:focus,
.appointment-form select:focus {
  outline: none;
  background: rgba(60, 60, 60, 1);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
}

/* Buttons */
.appointment-form button {
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(135deg, #555555, #666666);
  color: #ffffff;
  border: none;
  border-radius: 15px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.6);
}

.appointment-form button:hover {
  background: linear-gradient(135deg, #666666, #777777);
  transform: translateY(-3px);
  box-shadow: 0 10px 30px rgba(255, 255, 255, 0.2);
}

.appointment-form button:active {
  transform: translateY(0);
  box-shadow: none;
  background: rgba(40, 40, 40, 0.9);
}

/* Fehler- und Erfolgsmeldungen */
.error {
  color: #e74c3c;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  border-radius: 12px;
  background: rgba(231, 76, 60, 0.15);
  border: 1px solid rgba(231, 76, 60, 0.3);
}

.success {
  color: #27ae60;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  border-radius: 12px;
  background: rgba(46, 204, 113, 0.15);
  border: 1px solid rgba(46, 204, 113, 0.3);
}

/* React Calendar Styling */
.react-calendar {
  margin: 30px auto;
  border-radius: 15px;
  border: 1px solid rgba(50, 50, 50, 0.8);
  background: rgba(30, 30, 30, 0.95);
  color: #ffffff;
  padding: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.6);
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
}

.react-calendar__tile {
  text-align: center;
  padding: 12px 0;
  border-radius: 10px;
  transition: background 0.3s ease, color 0.3s ease;
  background: rgba(40, 40, 40, 0.9);
  color: rgba(200, 200, 200, 0.9);
}

.react-calendar__tile:hover {
  background: rgba(50, 50, 50, 0.95);
  color: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.react-calendar__tile--active {
  background: rgba(60, 60, 60, 1);
  color: #ffffff;
  border: 1px solid rgba(200, 200, 200, 0.5);
}

.react-calendar__tile--now {
  background: rgba(80, 80, 80, 0.9);
  color: #ffffff;
}

.react-calendar__month-view__days__day--weekend {
  color: rgba(255, 100, 100, 0.9);
}

/* Buttons für Navigation */
.react-calendar__navigation button {
  background: rgba(50, 50, 50, 0.9);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: all 0.3s ease;
  font-size: 14px;
}

.react-calendar__navigation button:hover {
  background: rgba(60, 60, 60, 1);
  color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

/* Responsive Design */
@media (max-width: 480px) {
  .appointment-form {
    padding: 25px;
  }

  .appointment-form h2 {
    font-size: 24px;
  }

  .logout-button {
    padding: 10px 15px;
    font-size: 12px;
  }

  .appointment-form button {
    font-size: 16px;
  }

  .react-calendar {
    padding: 15px;
  }
}
