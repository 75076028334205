/* Grundstile für die gesamte App */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: url('./saficuts.jpg') no-repeat center center fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  color: #ffffff; /* Weißer Text auf dunklem Hintergrund */
  min-height: 100vh;
  display: flex;
  flex-direction: column; /* Inhalte vertikal ausrichten */
  justify-content: center;
  align-items: center;
}

/* Container für zentrale Boxen */
.container {
  max-width: 800px;
  width: 100%; /* Passt sich kleineren Bildschirmen an */
  margin: 50px auto;
  padding: 30px;
  background: rgba(20, 20, 20, 0.85); /* Tiefes Schwarz mit Transparenz */
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.7); /* Tiefer Schatten */
  overflow-x: auto; /* Verhindert Abschneiden von Inhalten */
  text-align: center;
}

/* Kopfzeilen */
h1,
h2,
h3 {
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}

h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background: rgba(255, 255, 255, 0.3);
  margin: 10px auto 0;
  border-radius: 3px;
}

/* Buttons */
button {
  display: inline-block;
  padding: 14px 20px;
  margin: 5px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  background: linear-gradient(135deg, #444444, #555555);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
}

button:hover {
  background: linear-gradient(135deg, #555555, #666666);
  transform: translateY(-2px);
}

button:disabled {
  background: rgba(40, 40, 40, 0.5);
  cursor: not-allowed;
  box-shadow: none;
}

/* Tabellen */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: rgba(40, 40, 40, 0.9); /* Dunkler Hintergrund */
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  font-size: 16px;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

th {
  background: rgba(50, 50, 50, 1);
  text-transform: uppercase;
  font-weight: 600;
}

tr:nth-child(even) {
  background: rgba(60, 60, 60, 0.8);
}

tr:hover {
  background: rgba(80, 80, 80, 0.9);
}

.no-data {
  text-align: center;
  font-style: italic;
  color: rgba(255, 255, 255, 0.5);
}

/* Formulare */
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  text-align: left;
}

input,
select {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border: none;
  border-radius: 12px;
  background: rgba(40, 40, 40, 0.9);
  color: #ffffff;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

input::placeholder,
select::placeholder {
  color: rgba(200, 200, 200, 0.6);
  font-style: italic;
}

input:focus,
select:focus {
  outline: none;
  background: rgba(50, 50, 50, 1);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

/* Nachricht und Fehleranzeigen */
.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  background: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.2);
}

.success {
  color: #27ae60;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  background: rgba(46, 204, 113, 0.1);
  border: 1px solid rgba(46, 204, 113, 0.2);
}

/* Kalender */
.react-calendar {
  margin: 30px auto;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: rgba(40, 40, 40, 0.9);
  color: #ffffff;
  padding: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.react-calendar__tile {
  text-align: center;
  padding: 15px 0;
  border-radius: 8px;
  transition: background 0.3s ease, color 0.3s ease;
  background: rgba(50, 50, 50, 0.8);
  color: #ffffff;
}

.react-calendar__tile--now {
  background: rgba(70, 70, 70, 0.9);
  color: #ffffff;
  font-weight: bold;
  border-radius: 8px;
}

.react-calendar__tile--active {
  background: #444444;
  color: #ffffff;
}

.react-calendar__tile:hover {
  background: rgba(60, 60, 60, 0.9);
}



  table {
    font-size: 14px;
    overflow-x: auto; /* Tabellen scrollen lassen */
  }

  th,
  td {
    padding: 10px;
  }

  h2 {
    font-size: 24px;
  }

  button {
    font-size: 14px;
    padding: 10px 15px;
  }


@media (max-width: 480px) {
  .container {
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  table {
    font-size: 12px;
  }

  th,
  td {
    padding: 8px;
  }

  button {
    font-size: 12px;
    padding: 8px 10px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1000px; /* Breite für größere Bildschirme erhöhen */
    padding: 40px;
  }

  table {
    font-size: 18px;
  }

  th,
  td {
    padding: 15px;
  }

  button {
    font-size: 16px;
    padding: 12px 20px;
  }
}
