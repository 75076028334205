/* Wrapper für das Admin Panel */
.admin-panel-wrapper {
  height: 100vh; /* Vollbildhöhe */
  overflow-y: auto; /* Ermöglicht vertikales Scrollen */
  background: #101010; /* Hintergrund für gesamte Ansicht */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Inhalte oben starten */
  padding: 20px; /* Innenabstand für mobilen Komfort */
  box-sizing: border-box; /* Sicherstellen, dass Padding berücksichtigt wird */
}

/* Admin Panel */
.admin-panel {
  max-width: 800px; /* Breitere Ansicht für Tabellen */
  width: 100%;
  background: rgba(20, 20, 20, 0.85); /* Dunkler Hintergrund mit Transparenz */
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.7); /* Tiefer Schatten */
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  padding: 30px; /* Innenabstand */
  box-sizing: border-box;
}

/* Dekorativer Akzent */
.admin-panel::before {
  content: '';
  position: absolute;
  top: -20%;
  left: -20%;
  width: 150%;
  height: 150%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.05), transparent);
  z-index: -1;
}

/* Header */
.header {
  position: sticky; /* Fixiert Header oben */
  top: 0;
  z-index: 10;
  background: rgba(20, 20, 20, 0.95); /* Hintergrund, wenn fixiert */
  padding: 15px 0;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Dezente Trennung */
}

.header h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.header h2::after {
  content: '';
  width: 60px;
  height: 3px;
  background: rgba(255, 255, 255, 0.3);
  display: block;
  margin: 10px auto 0;
  border-radius: 3px;
}

/* Logout-Button */
.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 12px 20px;
  font-size: 14px;
  background: linear-gradient(135deg, #444444, #555555);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.logout-button:hover {
  background: linear-gradient(135deg, #555555, #666666);
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(255, 255, 255, 0.1);
}

/* Kalenderbereich */
.calendar-section {
  margin-top: 20px;
  padding: 15px;
  background: rgba(30, 30, 30, 0.9);
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.calendar-section h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

/* Tabellenstil */
.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  color: #ffffff;
  background: rgba(40, 40, 40, 0.9);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.admin-table th,
.admin-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-table th {
  background: rgba(50, 50, 50, 1);
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.admin-table tr:nth-child(even) {
  background: rgba(60, 60, 60, 0.8);
}

.admin-table tr:hover {
  background: rgba(80, 80, 80, 0.9);
}

.no-data {
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
}

/* Bearbeitungsformular */
.edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.edit-form input {
  padding: 14px;
  font-size: 16px;
  border: none;
  border-radius: 12px;
  background: rgba(40, 40, 40, 0.9);
  color: #ffffff;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.edit-form input:focus {
  outline: none;
  background: rgba(50, 50, 50, 1);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.edit-form button {
  width: fit-content;
  align-self: flex-end;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(135deg, #444444, #555555);
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.edit-form button:hover {
  background: linear-gradient(135deg, #555555, #666666);
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

/* Fehlermeldungen */
.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  background: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.2);
}

.success {
  color: #27ae60;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  background: rgba(46, 204, 113, 0.1);
  border: 1px solid rgba(46, 204, 113, 0.2);
}

/* Responsive Design */
@media (max-width: 480px) {
  .admin-panel {
    padding: 20px;
  }

  .admin-panel h2 {
    font-size: 24px;
  }

  .admin-table th,
  .admin-table td {
    padding: 10px;
    font-size: 14px;
  }

  .edit-form button {
    font-size: 14px;
  }

  .logout-button {
    padding: 8px 15px;
    font-size: 12px;
  }
}

.icon-button {
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 18px;
  margin: 0 5px;
  transition: color 0.3s ease;
}

.icon-button:hover {
  color: #000;
}

.blocked-dates-section {
  margin-top: 20px;
  padding: 15px;
  background: rgba(30, 30, 30, 0.9);
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.blocked-dates-section h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

.blocked-dates-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.blocked-dates-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background: rgba(40, 40, 40, 0.9);
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.05);
}

.delete-button {
  padding: 8px 15px;
  font-size: 14px;
  background: linear-gradient(135deg, #e74c3c, #c0392b);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.delete-button:hover {
  background: linear-gradient(135deg, #c0392b, #e74c3c);
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(255, 255, 255, 0.1);
}
