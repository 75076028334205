/* Basis-Styles für das Body-Element */
html, body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #000000, #1c1c1c);
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  overflow: auto; /* Ermöglicht Scrollen bei kleinen Bildschirmen */
}

/* Login-Container */
.login {
  width: 100%;
  max-width: 400px; /* Angepasste Breite */
  padding: 30px;
  border-radius: 15px;
  background: rgba(20, 20, 20, 0.9);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
  text-align: center;
  box-sizing: border-box; /* Verhindert Überlauf durch Padding */
  margin: 20px;
}

/* Überschrift */
.login h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}

/* Formular */
.login form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Eingabefelder */
.login input {
  padding: 15px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  background: rgba(40, 40, 40, 0.8);
  color: #ffffff;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.login input::placeholder {
  color: rgba(200, 200, 200, 0.7);
}

.login input:focus {
  outline: none;
  background: rgba(50, 50, 50, 0.9);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

/* Passwortfeld mit Augensymbol */
.password-container {
  position: relative;
}

.password-container input {
  padding-right: 45px; /* Platz für das Eye-Icon */
}

.password-container .eye-icon {
  position: absolute;
  top: 50%;
  right: 15px; /* Abstand vom Rand */
  transform: translateY(-50%);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.password-container .eye-icon:hover {
  color: #ffffff;
}

/* Buttons */
.login button {
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(135deg, #444444, #555555);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.login button:hover {
  background: linear-gradient(135deg, #555555, #666666);
  transform: translateY(-2px);
}

.login button:active {
  transform: translateY(0);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Umschaltbutton für Login und Registrierung */
.toggle-login {
  margin-top: 15px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.toggle-login:hover {
  color: #ffffff;
}

/* Fehlermeldung */
.error {
  color: #e74c3c;
  font-size: 13px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.2);
}

/* Erfolgsmeldung */
.success {
  color: #27ae60;
  font-size: 13px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(46, 204, 113, 0.1);
  border: 1px solid rgba(46, 204, 113, 0.2);
}

/* Nachricht */
.message {
  color: #3498db;
  font-size: 13px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(52, 152, 219, 0.1);
  border: 1px solid rgba(52, 152, 219, 0.2);
}

/* Impressum */
.impressum {
  margin-top: 30px;
  padding: 20px;
  background: rgba(40, 40, 40, 0.8);
  border-radius: 8px;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.impressum h3 {
  margin-bottom: 10px;
  font-size: 14px;
  color: #ffffff;
}

.impressum p {
  margin: 0 0 10px;
}

/* Responsives Design */
@media (max-width: 768px) {
  .login {
    width: 90%;
    padding: 20px;
  }

  .login h2 {
    font-size: 20px;
  }

  .login button {
    font-size: 13px;
    padding: 12px;
  }

  .password-container .eye-icon {
    font-size: 16px;
  }

  .impressum {
    padding: 15px;
    font-size: 11px;
  }

  .impressum h3 {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .login {
    padding: 15px;
  }

  .login h2 {
    font-size: 18px;
  }

  .login button {
    font-size: 12px;
    padding: 10px;
  }

  .password-container .eye-icon {
    font-size: 14px;
  }

  .impressum {
    font-size: 10px;
  }

  .impressum h3 {
    font-size: 12px;
  }
}


